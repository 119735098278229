import { ref, onMounted, onUnmounted, readonly } from 'vue'
import { getDeviceByUserAgent, getDeviceByHeaders } from 'theme/helpers/device';

interface DeviceInfo {
  type: string,
  isMobile: boolean,
  isTablet: boolean,
  screenWidth: number
}

// Спільний стан
const deviceInfo = ref<DeviceInfo>({
  type: 'desktop',
  isMobile: false,
  isTablet: false,
  screenWidth: 0
})

const subscribersCount = ref(0)

function detectDeviceFromHeaders (headers: any): DeviceInfo {
  const {
    device,
    deviceType,
    isMobileHeader
  } = getDeviceByHeaders(headers)

  return {
    type: deviceType,
    isMobile: isMobileHeader || device.type === 'mobile',
    isTablet: device.type === 'tablet',
    screenWidth: 0
  }
}

function throttle (func: (...args: any[]) => void, limit: number) {
  let inThrottle: boolean

  return function (...args: any[]) {
    if (!inThrottle) {
      func.apply(this, args)
      inThrottle = true

      setTimeout(() => {
        inThrottle = false
      }, limit)
    }
  }
}

/**
 * Композабл для визначення типу пристрою та відслідковування розміру екрану
 *
 * @param {DeviceInfo} [initialDeviceInfo] - Початкові дані про пристрій (зазвичай з SSR)
 *
 * @returns {{
 *   deviceInfo: Readonly<Ref<DeviceInfo>>
 * }} Об'єкт, що містить readonly референс з інформацією про пристрій
 *
 * @example
 * ```typescript
 * // Використання в компоненті
 * export default {
 *   setup() {
 *     const { deviceInfo } = useDevice()
 *
 *     return {
 *       isMobile: computed(() => deviceInfo.value.isMobile)
 *     }
 *   }
 * }
 * ```
 *
 * @example
 * ```typescript
 * // Використання з SSR
 * export default {
 *   async asyncData({ store, context }) {
 *     if (process.server && context?.req?.headers) {
 *       const UAParser = require('ua-parser-js')
 *       const ua = new UAParser(context.req.headers['user-agent'])
 *       const device = ua.getDevice()
 *
 *       const serverDeviceInfo = {
 *         type: device.type || 'desktop',
 *         isMobile: device.type === 'mobile',
 *         isTablet: device.type === 'tablet',
 *         screenWidth: 0 // На сервері не знаємо розмір екрану
 *       }
 *       // Зберігаємо в store
 *       await store.dispatch('ui/setDeviceInfo', serverDeviceInfo)
 *     }
 *   },
 *   setup(props) {
 *     const { deviceInfo } = useDevice(this.$store.state.ui.deviceInfo)
 *   }
 * }
 * ```
 *
 * @description
 * Композабл створює спільний стан для всіх компонентів.
 * Автоматично оновлює дані при зміні розміру вікна з throttling.
 * Підтримує SSR з гідратацією на клієнті.
 * Автоматично очищує слухачі подій коли більше не використовується.
 */
export function useDevice (initialDeviceInfo?: DeviceInfo) {
  // Ініціалізуємо дані з SSR якщо вони є
  if (initialDeviceInfo) {
    deviceInfo.value = {
      ...deviceInfo.value,
      ...initialDeviceInfo
    }
  }

  const updateDeviceInfo = () => {
    if (typeof window !== 'undefined') {
      deviceInfo.value = getDeviceByUserAgent(window.navigator.userAgent)
    }
  }

  const throttledUpdate = throttle(updateDeviceInfo, 250)

  onMounted(() => {
    subscribersCount.value++

    if (deviceInfo.value.screenWidth === 0) {
      deviceInfo.value.screenWidth = window.innerWidth
    }

    if (subscribersCount.value === 1) {
      window.addEventListener('resize', throttledUpdate)
    }
  })

  onUnmounted(() => {
    subscribersCount.value--
    if (subscribersCount.value === 0) {
      window.removeEventListener('resize', throttledUpdate)
    }
  })

  return {
    deviceInfo: readonly(deviceInfo),
    detectDeviceFromHeaders
  }
}
